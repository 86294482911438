import React, { useEffect, useState } from "react";
import { Coordinates } from "services/useElementCoordinates";
import './interactiveItem.scss'

function InteractiveItem({ selectedLabel, coordinates }: { selectedLabel: string | undefined, coordinates: Coordinates }) {
    const [itemCoorditates, setItemCoorditates] = useState<{ top: number | string; left: number | string }>()

    useEffect(() => {
        switch (selectedLabel) {
            case 'input': {
                // setItemCoorditates({ top: 0, left: coordinates.left - 220 })
                setItemCoorditates({ top: '5%', left:'14%' })
                break;
            }
            case 'dashboard':{
                setItemCoorditates({ top: "42%", left:'27.5%'})
                break;
            }
            case 'output':{
                setItemCoorditates({ top: '15%', left: '75%'  })
                break;
            }
        }
    }, [selectedLabel])
    // useEffect(() => {
    //     switch (selectedLabel) {
    //         case 'input': {
    //             setItemCoorditates({ top: 30, left: coordinates.left - 220 })
    //             break;
    //         }
    //         case 'dashboard':{
    //             setItemCoorditates({ top: coordinates.bottom+70, left:coordinates.left-510})
    //             break;
    //         }
    //         case 'output':{
    //             setItemCoorditates({ top: 105, left:coordinates.right - 30  })
    //             break;
    //         }
    //     }
    // }, [selectedLabel, coordinates])

    return ( 
        <div className={`${selectedLabel}`} style={{ top: itemCoorditates?.top, left: itemCoorditates?.left}}>    
            <div className={`figure_${selectedLabel}`} >
                <p>{selectedLabel}</p>
            </div>
        </div>
    )
}

export default InteractiveItem