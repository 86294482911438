import { useCallback, useRef, useState } from 'react';
import classNames from 'classnames';
import './Select.scss';


type SelectProps = {
    className?: string;
    items: Array<string>;
    value: string | null;
    setValue: React.Dispatch<React.SetStateAction<string | null>>;
}

export default function Select({ className, items, value, setValue }: SelectProps) {
    const select = useRef<HTMLDivElement>(null);
    const [, setActive] = useState(false);

    const handleSetValue = useCallback((item: string) => {
        setValue(item);
        select.current?.blur();
    }, [setValue]);

    const handleClick = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const target = e.currentTarget;
        setActive(prev => {
            if (prev) target.blur();
            else target.focus();
            return !prev;
        });
    }, []);

    return (
        <div ref={select} tabIndex={0} className={classNames('select', className)} onClick={handleClick}>
            <div className={classNames({ select__placeholder: true, select__placeholder_selected: Boolean(value) })}>{value || 'Select your profession'}</div>
            <div className="select__button" />
            <div className="select__items">
                {
                    items.map(item => (
                        <div className="select__item" key={item} onClick={() => handleSetValue(item)}>{item}</div>
                    ))
                }
            </div>
        </div>
    );
}