import React, { useEffect, useState } from "react";
import './Text.scss'
import { textArr } from "pages/main/config";
import { ReactComponent as Point } from "../../../../icons/point.svg"


function TextElement() {
    const [scrollPercentage, setScrollPercentage] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const text = document.getElementById('trans_text');
            if (!text) return;
            const elementRect = text.getBoundingClientRect();
            const windowHeight = window.innerHeight;
            const visibleHeight = windowHeight - elementRect.top;
            const percentVisible = (visibleHeight / elementRect.height);
            // console.log(percentVisible)

            setScrollPercentage(percentVisible)
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <section className="section_text" id="vision">
            <div className="text">
                <div className="list">
                    <div className="text_list-item" >
                        <Point />
                        <p>Leave behind the frustrations of complex software / scattered communication / research management conventions / and other trivial matters that interrupt the creative flow.</p>
                    </div>
                    <div className="text_list-item">
                        <Point />
                        <p>Let I-O be your caretaker, allowing you to focus on creativity / feasibility / ecological impact, or whatever matters to you.</p>
                    </div>


                </div>
                <div className="trans-text" id="trans_text">
                    {textArr.map((line, index) => (
                        <>
                            {line.line.map((word, index) => {
                                const opacityIncrement = 0.3 + (scrollPercentage * 0.1);
                                return (
                                    <div key={`word_${index}`} className="trans-text-item"
                                        style={{
                                            opacity: scrollPercentage - word.position >= line.scrollposition ? 0.5 + ((opacityIncrement)) : 0.5

                                        }}>
                                        {word.word}
                                    </div>
                                )
                            }
                            )}
                        </>
                    ))}
                </div>
            </div>

        </section>

    );
}

export default TextElement;

// opacity: scrollPercentage - word.position >= line.scrollposition ? 0.5 + ((scrollPercentage-0.4)) : 0.5

