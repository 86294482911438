import React, { useEffect, useState } from "react";
import Feature from "../feature/Feature";
import "./Slider.scss";


function Slider() {

  const [slide, setSlide] = useState(0);

  useEffect(() => {
    function fn() {
      const slider = document.getElementById('features');
      if (!slider) return;
      const offset = slider.getBoundingClientRect().top;
      if (offset > 0) return;
      const height = slider.scrollHeight - globalThis.innerHeight;
      const pos = Math.abs(offset) / height;
      if (pos < 0.33) return setSlide(0);
      if (pos < 0.66) return setSlide(1);
      return setSlide(2);
    }

    document.addEventListener('scroll', fn);
    return () => document.removeEventListener('scroll', fn);
  }, []);

  return (
    <section className="features" id="features" >
      <section id="section_input" className="features_item" style={{ display: slide === 0 ? 'flex' : 'none' }}>
        <Feature type="input" />
      </section>
      <section id="section_dashboard" className="features_item" style={{ display: slide === 1 ? 'flex' : 'none' }}>
        <Feature type="dashboard" />
      </section>
      <section id='section_output' className="features_item" style={{ display: slide === 2 ? 'flex' : 'none' }}>
        <Feature type="output" />
      </section>
    </section>

  );
}

export default Slider;
