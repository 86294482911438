import config from "config";

export async function googleAuth(code: string): Promise<{ email: string, id: string }> {
    const res = await fetch(`${config.backendUrl}/auth/google`, {
        method: 'POST',
        body: JSON.stringify({ code }),
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
        },
    });

    if (!res.ok) throw new Error('Unknown error.');
    const data = await res.json();

    return data.result;
}

export async function magicAuth(token: string): Promise<{ email: string, id: string }> {
    const res = await fetch(`${config.backendUrl}/auth/magic`, {
        method: 'POST',
        body: JSON.stringify({ token }),
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
        },
    });

    if (!res.ok) throw new Error('Unknown error.');
    const data = await res.json();

    return data.result;
}