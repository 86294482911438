import classNames from 'classnames';
import './Radio.scss';


type RadioProps = {
    className?: string;
    collections: Array<string>;
    value: string | null;
    setValue: (value: string) => void;
};

export default function Radio({ className, collections, value, setValue }: RadioProps) {
    return (
        <div className={classNames('radio', className)}>
            {
                collections.map(option => <Button key={option} value={option} selected={value === option} select={value => setValue(value)} />)
            }
        </div>
    );
}

type ButtonProps = {
    value: string;
    selected: boolean;
    select: (value: string) => void;
};

function Button({ value, selected, select }: ButtonProps) {
    return (
        <div className="radio-button">
            <div className={classNames({ 'radio-button__input': true, 'radio-button__input_selected': selected })} onClick={() => select(value)}>
            </div>
            <div className="radio-button__label">{value}</div>
        </div>
    );
}