import React, { useEffect, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import { ReactComponent as Point } from "../../../../../icons/point.svg"
import { ReactComponent as Share } from "../../../../../icons/Share.svg"

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import './MobileSlider.scss';
import { IFeature, featuresItems } from 'pages/main/config';
import { shareContent } from 'services';

export default function MobileSlider() {
    const [activeSlide, setActiveSlide] = useState<number>(0)

    const [feature, setFeature] = useState<IFeature>(featuresItems[activeSlide])

    useEffect(() => {
        setFeature(featuresItems[activeSlide])
    }, [activeSlide])

    // const shareContent = async () => {
    //     try {
    //       await navigator.share({
    //         // title: 'Заголовок вашей страницы',
    //         text: 'Launch on Desktop',
    //         url: window.location.href,
    //       });
    //     } catch (error) {
    //       console.log(error);
    //     }
    //   };
   
    const handleShare = async () => {
        await shareContent();
    };

    return (
        <section className="mobile-section" id='features'>
            <Swiper
                slidesPerView={'auto'}
                centeredSlides={true}
                spaceBetween={20}
                pagination={{
                    clickable: true,
                }}
                className="mySwiper"
                style={{
                    transform: 'translate3d(0px, 0px, 0px)',
                }}
                onSlideChange={(swiper: SwiperClass) => {
                    setActiveSlide(swiper.activeIndex);
                }}
            >
                <SwiperSlide>Animation</SwiperSlide>
                <SwiperSlide>Animation</SwiperSlide>
                <SwiperSlide>Animation</SwiperSlide>
            </Swiper>
            <div>
                <div className="mobile-feature-info_wrapper">
                    <div className="mobile-feature-info">
                        <p className="mobile-feature-info_name">{feature.type}</p>
                        <div className="mobile-feature-info_descr">
                            <h4>{feature.promo}</h4>
                            <p>{feature.descr}</p>
                        </div>
                        <div className="mobile-feature-info_options">
                            <div className="mobile-feature-info_list">
                                {feature.featureList.map((featuresItem, index) => (
                                    <div className="mobile-feature-info_list-item" >
                                        <Point />

                                        <p key={`list_item_${index}`}>{featuresItem}</p>
                                    </div>
                                ))}
                            </div>
                            <button onClick={handleShare}>
                                TRY DEMO ON DESKTOP
                                <Share className='mobile-feature-info_button-icon' />
                            </button>
                         
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
