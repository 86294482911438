export const shareContent = async () => {
    try {
      await navigator.share({
        // title: 'Заголовок вашей страницы',
        text: 'Launch on Desktop',
        url: window.location.origin,
      });
    } catch (error) {
      console.log(error);
    }
  };

export const goToService = () => {
    window.open('https://demo.quarters-dev.site/', '_blank');
}

  // export const shareContent = async (setIsHandleShareReady :any) => {
  //   try {
  //     await navigator.share({
  //       // title: 'Заголовок вашей страницы',
  //       text: 'Launch on Desktop',
  //       url: window.location.href,
  //     });
  //     setIsHandleShareReady(true)
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };