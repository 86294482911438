import { createEvent, createStore } from "effector";

export const $modal = createStore<boolean>(false);

export const openModal = createEvent();
export const closeModal = createEvent();

$modal
    .on(openModal, () => true)
    .on(closeModal, () => false);
