import { shareContent } from "services";
import { ReactComponent as Arrow } from "../../../../../icons/arrowRight.svg"
import { ReactComponent as Share } from "../../../../../icons/Share.svg"
import './MobileInteractiveTitle.scss'
import { openModal } from "entities/modal";
import { useStore } from "effector-react";
import { $email } from "entities/user";

function MobileInteractiveTitle() {

    const handleShare = async () => {
        await shareContent();
    };
    const email = useStore($email)


   
    const handleWordClick = (sliderNumber: number) => {
        // const section = document.getElementById('slider');

        // if (section) {
        //     section.scrollIntoView({ block: sliderNumber === 0 ? 'start' : sliderNumber === 1 ? 'center' : 'end', behavior: 'smooth' });

        // }
    };

    return (
        <section className="mobile-section">
            <div className='mobile_preview'>
                <div className="mobile_preview-text-wrapper">
                    <div className='mobile_preview-text'>Developing a tech-driven lab for architects/<br />interior designers / other design professionals</div>
                    <h1>
                        <span className='highlight'
                            onClick={() => handleWordClick(0)}>
                            Explore
                        </span>,<br />
                        <span className='highlight'
                            id='dashboard'
                            onClick={() => handleWordClick(1)}>
                            set up
                        </span>{' '}
                        and{' '}
                        <span className='highlight'
                            id='output'
                            onClick={() => handleWordClick(2)}>
                            prototype
                        </span><br />
                        designs
                    </h1>
                </div>

                <h4>I-O revitalizes the creative process, enabling creative professionals to achieve the lifestyle they dream about.</h4>
                <div className='mobile_preview-buttons-wrapper'>
                      {email ? ' ' :
                        <button onClick={() => openModal()}>
                            sign up to get early access
                            <Arrow className='mobile_preview-button-icon' />
                        </button>}
                    <button style={{ borderRadius: '30px' }} onClick={handleShare}>
                    try demo on desktop
                        <Share  height={19} className='mobile_preview-button-icon' />
                    </button>
                </div>
            </div>
        </section>
    )
}

export default MobileInteractiveTitle