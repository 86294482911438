import { createEffect, createEvent, createStore, sample } from "effector";
import config from "config";
import { googleAuth, magicAuth } from "services/user";

enum LocalStorage {
  USER = "USER",
}

export const $email = createStore<string | null>(getUserFiledFromLS("email"));
export const $userId = createStore<string>(getUserFiledFromLS("id"));
export const $guest = createStore(
  getUserFiledFromLS("id") === config.guestAccount.id
);

export const setEmail = createEvent<string>();
const popstate = createEvent<any>();

export const googleAuthFx = createEffect(googleAuth);
export const magicAuthFx = createEffect(magicAuth);

// $email
// .on(setEmail, (_, email) => email);
$email
  .on(googleAuthFx.doneData, (_, { email }) => email)
  .on(magicAuthFx.doneData, (_, { email }) => email);

$userId
  .on(googleAuthFx.doneData, (_, { id }) => id)
  .on(magicAuthFx.doneData, (_, { id }) => id);

$guest.on($userId, (_, value) => value === config.guestAccount.id);

googleAuthFx.doneData.watch(auth);
magicAuthFx.doneData.watch(auth);


sample({
  clock: popstate,
  filter: () => Boolean(window.location.search),
  fn: () => {
    const code = new URLSearchParams(window.location.search).get("code");
    window.history.replaceState(null, "", window.location.origin);
    if (!code) throw new Error("Code not exist.");
    return code;
  },
  target: googleAuthFx,
});

window.addEventListener("load", popstate);

window.addEventListener("popstate", popstate);


function auth(user: { email: string; id: string }) {
  if (typeof user === "string") throw new Error("Auth error.");
  document.cookie = `${LocalStorage.USER}=${JSON.stringify(user)}; domain=quarters-dev.site; expires=Sun, 05 Mar 2124 17:00:00 GMT`;
  const url = new URL(window.location.href);
  url.search = "";
  window.history.replaceState({}, "", url.toString());
}

function getUserFiledFromLS(field: "email" | "id") {
  const user = document.cookie.replace(/(?:(?:^|.*;\s*)USER\s*=\s*([^;]*).*$)|^.*$/, "$1");
  if (!user) return null;
  // if (!user) return config.guestAccount[field];
  if(!user) return null
  return JSON.parse(user)[field];
}
