import React, { useEffect } from 'react'
import './Main.scss'
import Header from '../components/header/Header'
import InteractiveTitle from './components/interactiveTitle/InteractiveTitle'
import Slider from './components/slider/Slider'
import TextElement from './components/text/Text'
import ButtonElement from './components/buttonElement/ButtonElement'
import Footer from '../components/footer/Footer'
import MyMedia from 'pages/components/media/MyMedia'
import TabletInteractiveTitle from './components/interactiveTitle/tablet/TabletInteractiveTitle'
import TabletFooter from '../components/footer/tablet/TabletFooter'
import MobileHeader from '../components/header/mobile/MobileHeader'
import MobileInteractiveTitle from './components/interactiveTitle/mobile/MobileInteractiveTitle'
import MobileSlider from './components/slider/mobile/MobileSlider'
import MobileButtonElement from './components/buttonElement/mobile/MobileButtonElement'
import MobileFooter from '../components/footer/mobile/MobileFooter'
import CookieNotification from './components/cookieNotification/CookieNotification'
import { useStore } from 'effector-react'
import { $sectionId } from 'entities/sectionId'
import Auth from 'pages/auth/Auth'
import { $modal } from 'entities/modal'

function Main() {

    const sectionId = useStore($sectionId)
    const modal = useStore($modal)

    useEffect(() => {
        console.log(modal)
    }, [modal])
    useEffect(() => {
        // console.log(sectionId)
        if (sectionId) {
            const section = document.getElementById(sectionId);
            if (section) {
                section.scrollIntoView({ block: 'start' });
            }
        }
    }, [sectionId])


    return (

        <>
            {modal && <Auth />}
            <div
                className={modal ? 'main-wrapper_modal_open' : 'main-wrapper.'}>

                <CookieNotification />
                {/* <Header /> */}
                <MyMedia
                    mobile={<MobileHeader />}
                    tablet={<Header />}
                    desktop={<Header />}
                />

                <MyMedia
                    mobile={<MobileInteractiveTitle />}
                    tablet={<TabletInteractiveTitle />}
                    desktop={<InteractiveTitle />}
                />
                <MyMedia
                    mobile={<MobileSlider />}
                    tablet={<Slider />}
                    desktop={<Slider />}
                />
                <TextElement />
                <MyMedia
                    mobile={<MobileButtonElement />}
                    tablet={<ButtonElement />}
                    desktop={<ButtonElement />}
                />
                <MyMedia
                    mobile={<MobileFooter />}
                    tablet={<TabletFooter />}
                    desktop={<Footer />}
                />

            </div>

        </>


    )
}

export default Main