import React, { useState } from "react";
import './ShareModal.scss'
import { ReactComponent as FacebookIcon } from './icons/facebook.svg'
import { ReactComponent as Twitter } from './icons/twitter.svg'
import { ReactComponent as In } from './icons/in.svg'
import { ReactComponent as Inst } from './icons/inst.svg'
import { ReactComponent as CopyLink } from '../../../icons/copyLink.svg'
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from "react-share";


function ShareModal({ type }: { type?: string }) {

    const [isCopied, setIsCopied] = useState<boolean>(false)

    const handleCopyLink = () => {
        const url = window.location.origin;
        navigator.clipboard.writeText(url)
            .then(() => {
                setIsCopied(true)
                setTimeout(() => {
                    setIsCopied(false);
                }, 1500);
            })
    }

    const handleLinkClick = (network: string) => {
        const url = window.location.origin;
        const text = (encodeURIComponent('This is awesome!'))

        switch (network) {
            case 'facebook': {
                window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}`, '_blank');
                break;
            }
            case 'twitter': {
                window.open(`https://twitter.com/share?url=${url}&text=${text}`, '_blank');
                break;
            }
            case 'linkedin': {
                window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${url}`, '_blank');
                break
            }
            // case 'inst' :{
            //     window.open('https://www.instagram.com/create/story','_blank' )
            // }
        }
    }

    return (
        <div className="share_modal" style={type === 'mobile_footer'? { width: '100%', height: 204 }: {}}>
            {/* {type === 'mobile_footer' ?
                <div className="mobile_share_close">
                    <div onClick={}>
                        <Close />
                    </div>


                </div> :
                <span className="share_modal_before" style={type === 'tablet-footer' ? { left: 44, right: 0 } : {}} />
            } */}
            {type!=='mobile_footer' && <span className="share_modal_before" style={type === 'tablet-footer' ? { left: 44, right: 0 } : {}} />} 
            
            <div className="share_icons_wrapper">

                <FacebookShareButton
                    className="share_icons_item"
                    url={window.location.origin}>
                    <FacebookIcon className="share_icon" />
                </FacebookShareButton>

                <TwitterShareButton
                    className="share_icons_item"
                    url={window.location.origin}>
                    <Twitter className="share_icon" />
                </TwitterShareButton>

                <LinkedinShareButton
                    className="share_icons_item"
                    url={window.location.origin}
                    summary={'This is awesome!!'}>
                    <In className="share_icon" />

                </LinkedinShareButton>
                <div className="share_icons_item">
                    <Inst className="share_icon" onClick={() => handleLinkClick('inst')} />
                </div>
            </div>
            <div className={isCopied ? 'copy_link_wrapper_copyed' : 'copy_link_wrapper'} 
            onClick={() => handleCopyLink()}>
                <CopyLink className="copy_link_icon" />
                {isCopied ? 'Link copied!' : 'Copy link'}
            </div>
        </div >
    )
}




export default ShareModal




// {/* <div className="share_icons_item" onClick={() => handleLinkClick('facebook')}>
// <FacebookIcon className="share_icon" />
// </div>
// <div className="share_icons_item" onClick={() => handleLinkClick('twitter')}>
// <Twitter className="share_icon" />
// </div>
// <div className="share_icons_item" onClick={() => handleLinkClick('linkedin')}>
// <In className="share_icon" />
// </div>
// <div className="share_icons_item">
// <Inst className="share_icon" onClick={() => handleLinkClick('inst')} />
// </div> */}

