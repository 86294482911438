const config = {
    // serverUrl: process.env.REACT_APP_SERVER_URL || '',
    backendUrl: process.env.REACT_APP_BECKEND_URL || '',
    magicPK: process.env.REACT_APP_MAGIC_KEY || '',
    googleId: process.env.REACT_APP_GOOGLE_ID || '',
    guestAccount: {
        id: '5fc876fa-406d-4497-962a-099d9052024c',
        email: 'guest@email.com',
    },
};

export default config;