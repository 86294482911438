import React from "react";
import './MobileButtonElement.scss'
import { ReactComponent as Share } from '../../../../../icons/Share.svg';
import { shareContent } from "services";

function MobileButtonElement() {
    
    const handleShare = async () => {
        await shareContent();
    };

    return (
        <section className="mobile_button_element_section" id="about">
            <div className="mobile_button_element-wrapper">
                <h1>Help us improve using our i-o demo</h1>
                <button onClick={handleShare}>
                    try demo on desktop
                    <Share className="mobile_button_element-icon"/>
                </button>
            </div>

        </section>
    )
}

export default MobileButtonElement