import React, { useEffect, useState } from "react";
import './Feature.scss'
import { ReactComponent as Arrow } from "../../../../icons/arrowRight.svg"
import { ReactComponent as Point } from "../../../../icons/point.svg"
import { IFeature, featuresItems } from "pages/main/config";
import { openModal } from "entities/modal";
import { $email } from "entities/user";
import { useStore } from "effector-react";
import { goToService } from "services";

function Feature({ type }: { type: string }) {
    const [feature, setFeature] = useState<IFeature>();
    const email = useStore($email)

    useEffect(() => {
        const foundItem = featuresItems.find((item) => item.type === type);
        if (foundItem) {
            setFeature(foundItem)
        }
    }, [type])

    return (
        <div className="feature-wrapper">
            <div className="feature-animation">

            </div>
            <div className="feature-info_wrapper">
                <div className="feature-info">
                    <p className="feature-info_name">{feature?.type}</p>
                    <div className="feature-info_descr">
                        <h4>{feature?.promo}</h4>
                        <p>{feature?.descr}</p>
                    </div>
                    <div className="feature-info_options">
                        <div className="feature-info_list">
                            {feature?.featureList.map((feature, index) => (
                                <div className="feature-info_list-item" key={`list_item_${index}`} >
                                    <Point />
                                    <p>{feature}</p>
                                </div>

                            ))}
                        </div>
                        <div className="feature-info_buttons">
                            {email ? '' :
                                <button onClick={() => openModal()}>
                                    Sign up
                                    <Arrow className='feature-info_button-icon' />
                                </button>}

                            <button style={{ borderRadius: 20 }} onClick={()=>goToService()}>
                                try desktop demo
                                <Arrow className='feature-info_button-icon' />
                            </button>
                        </div>

                    </div>
                </div>

            </div>

        </div>
    )
}
export default Feature