import React, { useState } from "react"
import useElementCoordinates from "services/useElementCoordinates";
import InteractiveItem from "../interactiveItem/InteractiveItem";
import { ReactComponent as Arrow } from "../../../../icons/arrowRight.svg"
import './InteractiveTitle.scss'
import { useStore } from "effector-react";
import { $modal, openModal } from "entities/modal";
import { $email } from "entities/user";
import { goToService } from "services";

function InteractiveTitle() {

    const modal = useStore($modal)
    const email = useStore($email)
    const [selectedLabel, setSelectedLabel] = useState<string | undefined>(undefined)
    const coordinates = useElementCoordinates(selectedLabel);
    const handleWordClick = (sliderNumber: number) => {
        const section = document.getElementById('features');
        if (section) {
            section.scrollIntoView({ block: sliderNumber === 0 ? 'start' : sliderNumber === 1 ? 'center' : 'end', behavior: 'smooth' });

        }
    };

    return (
        <section className={modal ? '' : 'preview_section'}>
            <div className='preview'>
                <InteractiveItem selectedLabel={selectedLabel} coordinates={coordinates} />
                <div className="preview-text-wrapper">
                    <div className='preview-text'>Developing a tech-driven lab for architects/<br />interior designers / other design professionals</div>
                    <h1>

                        <span className='highlight'
                            onClick={() => handleWordClick(0)}
                            id='input'
                            onMouseEnter={() => setSelectedLabel('input')}
                            onMouseLeave={() => setSelectedLabel(undefined)}
                        >
                            Explore
                            <div className="highlightBlock" />
                        </span>,{' '}
                        <span className='highlight'
                            id='dashboard'
                            onClick={() => handleWordClick(1)}
                            onMouseEnter={() => setSelectedLabel('dashboard')}
                            onMouseLeave={() => setSelectedLabel(undefined)}
                        >
                            set up
                            <div className="highlightBlock" />
                        </span><br />
                        and{' '}
                        <span className='highlight'
                            id='output'
                            onClick={() => handleWordClick(2)}
                            onMouseEnter={() => setSelectedLabel('output')}
                            onMouseLeave={() => setSelectedLabel(undefined)}
                        >
                            prototype
                            <div className="highlightBlock" />
                        </span><br />
                        designs
                    </h1>
                </div>

                <h4>I-O revitalizes the creative process, enabling<br />creative professionals to achieve the lifestyle<br />they dream about.</h4>
                <div className='preview-buttons-wrapper'>
                    {email ? ' ' :
                        <button onClick={() => openModal()}>
                            sign up to get early access
                            <Arrow className='preview-button-icon' />
                        </button>}

                    <button style={{ borderRadius: '20px' }} onClick={()=>goToService()}>
                        explore platform
                        <Arrow className='preview-button-icon' />
                    </button>
                </div>
            </div>
        </section>
    )
}

export default InteractiveTitle