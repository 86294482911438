import { useEffect, useState } from "react";

export interface Coordinates{
    top: number,
    left: number,
    bottom: number,
    right: number,
}

const useElementCoordinates = (id: string | undefined) => {
  const [coordinates, setCoordinates] = useState<Coordinates>({
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  });

  function getCoordinates(element: HTMLElement) {
    let top = 0;
    let left = 0;
    let currentElement: HTMLElement | null = element;

    while (currentElement) {
      top += currentElement.offsetTop;
      left += currentElement.offsetLeft;
      currentElement = currentElement.offsetParent as HTMLElement;
    }

    const bottom = top + element.offsetHeight;
    const right = left + element.offsetWidth;

    // console.log( 'top', top,'left', left,'bottom', bottom,'right' ,right)

    return { top, left, bottom, right };
  }

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
        if (id) {
          const targetElement = document.getElementById(id);
          if (targetElement) {
            setCoordinates(getCoordinates(targetElement));
          }
      }
    });
    resizeObserver.observe(document.documentElement);

    return () => {
      resizeObserver.disconnect();
    };
  }, [id]);

  return coordinates;
};

export default useElementCoordinates;
