import React from "react";
import './styles.scss'
import Header from "pages/components/header/Header";
import MobileHeader from "pages/components/header/mobile/MobileHeader";
import MyMedia from "pages/components/media/MyMedia";
import Footer from "pages/components/footer/Footer";
import MobileFooter from "pages/components/footer/mobile/MobileFooter";
import TabletFooter from "pages/components/footer/tablet/TabletFooter";
import { ReactComponent as Rectangle } from '../../icons/rectangle.svg'

function TermsOfUse() {

    return (
        <div className="wrapper">
            <MyMedia
                mobile={<MobileHeader />}
                tablet={<Header />}
                desktop={<Header />}
            />
            <div className="document_wrapper">
                <div className="document_info">
                    <div className="document_info-title_section">
                        <div className="document_info-title">
                            <h3>Terms of use</h3>
                            <p>Updated [date]</p>
                        </div>
                        <p>Aliquam donec pellentesque ut pulvinar tortor est. Fermentum urna auctor porta purus urna euismod. Congue lorem elementum sagittis ut elementum ac. Malesuada ultrices quis lectus nulla. Rhoncus eget fringilla porttitor integer eget venenatis viverra.</p>
                    </div>
                    <div className="document_info-section">
                        <h4>Subtitle</h4>
                        <div className="document_info-section_info">
                            <p>
                                <span className="bold">
                                    Eget ac pulvinar rhoncus duis sagittis.
                                </span>
                                Interdum arcu nulla diam enim non felis tincidunt. In quis erat curabitur id auctor pharetra. Habitant morbi ornare cras vestibulum nec. Mus sit vitae enim tincidunt. Tortor elementum maecenas duis non consequat tellus porttitor porta sed. Fames massa.
                            </p>
                            <p>
                                <span className="bold">
                                    Justo tortor eget mi eu ornare cursus.
                                </span>
                                Suspendisse proin ut nisi quam quis facilisis auctor. Laoreet aliquam felis faucibus pharetra vestibulum ipsum eget. Nibh amet amet ornare feugiat. Integer condimentum suspendisse sed eu nullam. Eget ut cursus non massa nisl est magna vitae. Lorem aliquam urna.
                            </p>
                            <p>
                                <span className="bold">
                                    Fringilla urna neque massa nunc nec habitasse tristique.
                                </span>
                                Tincidunt enim nunc pulvinar nisl lectus. Scelerisque mauris nisl auctor odio varius eleifend. Aliquam tempus non dui lectus. Cursus suscipit cursus fermentum aliquet ipsum dolor:
                            </p>
                            <div className="info_list-item">
                                <Rectangle className="list_marker" />
                                <p>Tincidunt enim nunc pulvinar nisl lectus. Scelerisque mauris nisl auctor odio varius eleifend. Aliquam tempus non dui lectus. Cursus suscipit cursus fermentum aliquet ipsum dolor.</p>
                            </div>
                            <div className="info_list-item">
                                <Rectangle className="list_marker" />
                                <p>Tincidunt enim nunc pulvinar nisl lectus. Scelerisque mauris nisl auctor odio varius eleifend. Aliquam tempus non dui lectus. Cursus suscipit cursus fermentum aliquet ipsum dolor.</p>
                            </div>
                            <div className="info_list-item">
                                <Rectangle className="list_marker" />
                                <p>Tincidunt enim nunc pulvinar nisl lectus. Scelerisque mauris nisl auctor odio varius eleifend. Aliquam tempus non dui lectus. Cursus suscipit cursus fermentum aliquet ipsum dolor.</p>
                            </div>
                        </div>

                    </div>
                    <div className="document_info-section">
                        <h4>Subtitle</h4>
                        <div className="document_info-section_info">
                            <p>
                                Interdum arcu nulla diam enim non felis tincidunt. In quis erat curabitur id auctor pharetra.
                                <span className="highlighted">Habitant morbi ornare</span>
                                {' '}cras vestibulum nec. Mus sit vitae enim tincidunt. Tortor elementum maecenas duis non consequat tellus porttitor porta sed. Fames massa.
                            </p>
                            <p>
                                Suspendisse proin ut nisi quam quis facilisis auctor. Laoreet aliquam felis faucibus pharetra vestibulum ipsum eget. Nibh <span className="highlighted">amet amet ornare</span>{' '}feugiat. Integer condimentum suspendisse sed eu nullam. Eget ut cursus non massa nisl est magna vitae. Lorem aliquam urna.
                            </p>
                            <p>
                                Tincidunt enim nunc pulvinar nisl lectus. Scelerisque mauris nisl auctor odio varius eleifend. Aliquam tempus non dui lectus. Cursus suscipit cursus fermentum aliquet ipsum dolor
                            </p>
                        </div>
                    </div>
                    <div className="document_info-section">
                        <h4>Subtitle</h4>
                        <div className="document_info-section_info">
                            <p>Interdum arcu nulla diam enim non felis tincidunt. In quis erat curabitur id auctor pharetra. Habitant morbi ornare cras vestibulum nec. Mus sit vitae enim tincidunt. Tortor elementum maecenas duis non consequat tellus porttitor porta sed. Fames massa.</p>
                            <p>Suspendisse proin ut nisi quam quis facilisis auctor. Laoreet aliquam felis faucibus pharetra vestibulum ipsum eget. Nibh amet amet ornare feugiat. Integer condimentum suspendisse sed eu nullam. Eget ut cursus non massa nisl est magna vitae. Lorem aliquam urna.</p>
                            <p>Tincidunt enim nunc pulvinar nisl lectus. Scelerisque mauris nisl auctor odio varius eleifend. Aliquam tempus non dui lectus. Cursus suscipit cursus fermentum aliquet ipsum dolor</p>
                        </div>
                    </div>
                </div>

            </div>
            <MyMedia
                mobile={<MobileFooter />}
                tablet={<TabletFooter />}
                desktop={<Footer />}
            />
        </div>
    )
}

export default TermsOfUse