import React, { useState } from 'react'
import './MobileFooter.scss'
import { ReactComponent as Logo } from '../../../../icons/logo.svg'
import { ReactComponent as Copyright } from '../icons/copyright.svg'
import { ReactComponent as CloseIcon } from '../../../../icons/close.svg'

import { Link } from 'react-router-dom'
import ShareModal from 'pages/components/shareModal/ShareModal'

import { ReactComponent as ShareIcon } from '../../../../icons/Share.svg'
import { setSectionId } from 'entities/sectionId'
import { useStore } from 'effector-react'
import { openModal } from 'entities/modal'
import { $email } from 'entities/user'

function MobileFooter() {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            // behavior: 'smooth',
        });
    };

    const [showShareModal, setShowShareModal] = useState<boolean>(false)
    const email = useStore($email)


    return (
        <section className='mobile_footer_section'>
            <div className='mobile_footer_info'>
                <Logo />
                <div className='mobile_footer_item'>
                    <div className='mobile_footer_cell'>
                        <Link
                            to='#'
                            onClick={(e) => {
                                window.location.href = 'mailto:mail@i-o.design';
                                e.preventDefault();
                            }}
                        >
                            mail@i-o.design
                        </Link>
                        <div className='mobile_footer-element-share'
                            onClick={() => setShowShareModal((prev) => !prev)}>
                            share
                            <ShareIcon className='footer-share-icon' />


                        </div>
                    </div>
                    <div className='mobile_footer_cell'>
                        <p>INSTAGRAM</p>
                        <p>LinkedIn</p>
                    </div>
                </div>
                <div className='mobile_footer_item'>
                    {/* style={{ display: showShareModal ? 'flex' : 'none' }} */}
                    <div className='share_modal_mobile_footer' style={{ display: showShareModal ? 'flex' : 'none' }}>
                        <div className='share_modal_mobile_footer_close'>
                            <div onClick={() => setShowShareModal(false)}>
                                <CloseIcon />
                            </div>
                        </div>
                        <ShareModal type='mobile_footer' />
                    </div>
                    <div className='mobile_footer_cell'>
                    <p onClick={() => {
                        if (!email) {
                            openModal()
                        }
                    }}> {email ? `${email}` : 'sign up'}</p>
                        <Link to={'/'} onClick={()=>setSectionId('features')}>coming Features</Link>
                        <Link to={'/'} onClick={()=>setSectionId('vision')}>vision</Link>
                        <Link to={'/'} onClick={()=>setSectionId('about')}>About</Link>
                    </div>
                    <div className='mobile_footer_cell'>
                        <Link to={'/terms'} onClick={scrollToTop}>terms of use</Link>
                        <Link to={'/privacy'} onClick={scrollToTop}>Privacy policy</Link>
                    </div>

                </div>
            </div>
            <Copyright />

        </section>

    )
}

export default MobileFooter;