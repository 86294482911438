import React, { useState } from 'react'
import './Footer.scss'
import { ReactComponent as Logo } from '../../../icons/logo.svg'
import { ReactComponent as Share } from '../../../icons/Share.svg'
import { ReactComponent as Copyright } from './icons/copyright.svg'

import { Link } from 'react-router-dom'

import ShareModal from '../shareModal/ShareModal'
import { setSectionId } from 'entities/sectionId'
import { openModal } from 'entities/modal'
import { useStore } from 'effector-react'
import { $email } from 'entities/user'

// import { shareContent } from 'services'

function Footer() {

    const [showShareModal, setShowShareModal] = useState<boolean>(false)
    const email = useStore($email)


    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            // behavior: 'smooth',
        });
    };


    return (
        <footer className='footer_section'>
            <div className='footer_wrapper'>
                <div className='footer_wrapper-element'>
                    <Logo />
                </div>
                <div className='footer_wrapper-element '>
                    <Link
                        to='#'
                        onClick={(e) => {
                            window.location.href = 'mailto:mail@i-o.design';
                            e.preventDefault();
                        }}
                    >
                        mail@i-o.design
                    </Link>
                    {/* <p>mail@i-o.design</p> */}
                    <div className='footer-element-share'
                        onMouseEnter={() => setShowShareModal(true)}
                        onMouseLeave={() => setShowShareModal(false)}
                    >
                        share
                        <Share className='footer-share-icon' />

                        <div className='share_modal_footer' style={{ display: showShareModal ? 'flex' : 'none' }}>
                            <ShareModal type='' />
                        </div>

                    </div>
                </div>
                <div className='footer_wrapper-element '>
                    <p>Instagram</p>
                    <p>LinkedIn</p>
                </div>
                <div className='footer_wrapper-element '>
                    <p onClick={() => {
                        if (!email) {
                            openModal()
                        }
                    }}> {email ? `${email}` : 'sign up'}</p>
                    <Link to={'/'} onClick={() => setSectionId('features')}>coming Features</Link>
                </div>
                <div className='footer_wrapper-element '>
                    <Link to={'/'} onClick={() => setSectionId('vision')}>vision</Link>
                    <Link to={'/'} onClick={() => setSectionId('about')}>About</Link>
                </div>
                <div className='footer_wrapper-element '>
                    <Link to={'/terms'} onClick={scrollToTop}>terms of use</Link>
                    <Link to={'/privacy'} onClick={scrollToTop}>Privacy policy</Link>
                </div>

            </div>
            <Copyright />

        </footer>

    )
}

export default Footer;