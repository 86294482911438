import React from 'react';
import Main from './pages/main/Main';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import PrivacyPolicy from 'pages/publicDocuments/PrivacyPolicy';
import TermsOfUse from 'pages/publicDocuments/TermsOfUse';

function App() {
  return (
    <Router>
      <Routes>
          <Route path="/:sectionId" element={<Main />} />
        <Route path="/" element={<Main />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<TermsOfUse />} />
      </Routes>
    </Router>

  );
}

export default App;
