import { useCallback, useState } from 'react';
import { Magic } from 'magic-sdk';
import config from 'config';
import { closeModal } from 'entities/modal';
import './Email.scss';
import Modal from '../modal/Modal';
import { magicAuthFx } from 'entities/user';


const magic = new Magic(config.magicPK);

// type EmailProps = {
//     onNextStep: () => void;
//     setTempUserEmail: (email: string | undefined) => void
// }

export default function Email() {
    const [email, setEmail] = useState<string | undefined>(undefined);
    const [checked, setChecked] = useState(false);
    const [isValidEmail, setIsValidEmail] = useState<boolean>(true)

    const onClickGoogle = useCallback(() => {
        const url = new URL('https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount');
        url.searchParams.append('response_type', 'code');
        url.searchParams.append('client_id', config.googleId);
        url.searchParams.append('redirect_uri', window.location.origin);
        url.searchParams.append('scope', 'email');

        window.location.href = url.toString();
    }, []);

    const onContinue = useCallback(async () => {
        if (!email) return
        // if (!isValidEmail ) return 0;

        // onNextStep()
        console.log(email, 'email')
        const token = await magic.auth.loginWithMagicLink({ email });
        if (!token) throw new Error('Token not exist.');
        console.log(token, 'token')
  
        magicAuthFx(token);
    }, [email]);

    const handleChange = () => {
        setChecked(!checked);
    }
    const emailValidation = (email: string) => {
        return /\S+@\S+\.\S+/.test(email);
    }

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const isValid = emailValidation(e.target.value);
        setIsValidEmail(isValid)
    };

    return (
        <Modal onClose={closeModal} textButton="Register" onButton={onContinue} isButtonDisabled={!isValidEmail || email === undefined}>
            <div className='email__modal_wrapper'>
                <h5 className='email__title'>Please register to submit</h5>
                <div className='email__form'>
                    <div className='email__form_item'>
                        <div className="email__name-label">NAME</div>
                        <input className='email__name' placeholder="Enter your name" />
                    </div>
                    <div className='email__form_item'>
                        <div className="email__email-label">EMAIL</div>
                        <input type="email" className='email__email'
                            placeholder="Enter your email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            onBlur={handleBlur}
                            onFocus={() => setIsValidEmail(true)}
                            style={{ borderColor: isValidEmail ? '' : '#F62F53' }} />
                        {!isValidEmail &&
                            <div className='email__invalid-notification'>
                                <p>Please enter a valid Email</p>
                            </div>}

                    </div>

                </div>

                {/* <Input className="email__name" placeholder="Enter your name" value={''} onChange={function (event: ChangeEvent<HTMLInputElement>): void {
                throw new Error('Function not implemented.');
            } } /> */}

                {/* <TextareaAutosize value={email} onChange={e => setEmail(e.target.value)} data-type="input" className="email__email" placeholder="Enter your email" /> */}
                <div className="email__or">OR</div>
                <div className="email__gmail" onClick={onClickGoogle}>Sign In with Google</div>
                <div className='email__subscribe-approve'>
                    <input type="checkbox" checked={checked} onChange={handleChange} />
                    <p>Yes, I would also like to Subscribe to our newsletter</p>
                </div>
                {/* <div className='email__rules'> */}
                <p className='email__rules'>
                    By clicking Register, you agree to our <a href="/terms" target="_blank">Terms of Use</a> and that you have read our <a href="/privacy" target="_blank">Privacy Policy</a>.
                </p>

                {/* </div> */}
            </div>

        </Modal>
    );
}