import { openModal } from "entities/modal";
import { ReactComponent as Arrow } from "../../../../../icons/arrowRight.svg"
import './TabletInteractiveTitle.scss'
import { useStore } from "effector-react";
import { $email } from "entities/user";

function TabletInteractiveTitle() {
    const email = useStore($email)
   
    const handleWordClick = (sliderNumber: number) => {
        const section = document.getElementById('features');
        if (section) {
            section.scrollIntoView({ block: sliderNumber === 0 ? 'start' : sliderNumber === 1 ? 'center' : 'end', behavior: 'smooth' });

        }
    };

    return (
        <section>
            <div className='tablet_preview'>
                <div className="tablet_preview-text-wrapper">
                    <div className='tablet_preview-text'>Developing a tech-driven lab for architects/<br />interior designers / other design professionals</div>
                    <h1>
                        <span className='highlight'
                            onClick={() => handleWordClick(0)}>
                            Explore
                        </span>,{' '}
                        <span className='highlight'
                            id='dashboard'
                            onClick={() => handleWordClick(1)}>
                            set up
                        </span><br />
                        and{' '}
                        <span className='highlight'
                            id='output'
                            onClick={() => handleWordClick(2)}>
                            prototype
                        </span><br />
                        designs
                    </h1>
                </div>

                <h4>I-O revitalizes the creative process, enabling<br />creative professionals to achieve the lifestyle<br />they dream about.</h4>
                <div className='tablet_preview-buttons-wrapper'>
                {email ? ' ' :
                        <button onClick={() => openModal()}>
                            sign up to get early access
                            <Arrow className='tablet_preview-button-icon' />
                        </button>}
                    {/* <button>
                        sign up to get early access
                        <Arrow className='tablet_preview-button-icon' />
                    </button> */}
                    <button style={{ borderRadius: '20px' }}>
                        explore platform
                        <Arrow className='tablet_preview-button-icon' />
                    </button>
                </div>
            </div>
        </section>
    )
}

export default TabletInteractiveTitle