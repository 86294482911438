import React, { useState } from 'react'
import './TabletFooter.scss'
import { ReactComponent as Logo } from '../../../../icons/logo.svg'
import { ReactComponent as Copyright } from './../icons/copyright.svg'
import { Link } from 'react-router-dom';
import ShareModal from 'pages/components/shareModal/ShareModal';
import { ReactComponent as ShareIcon } from '../../../../icons/Share.svg'
import { setSectionId } from 'entities/sectionId';
import { useStore } from 'effector-react';
import { openModal } from 'entities/modal';
import { $email } from 'entities/user';

function TabletFooter() {

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
        });
    };
    const [showShareModal, setShowShareModal] = useState<boolean>(false)
    const email = useStore($email)
    return (
        <footer className='tablet_footer_section'>
            <div className='tablet_footer_wrapper'>
                <div className='tablet_footer_wrapper-element'>
                    <Logo />
                </div>
                <div className='tablet_footer_second-row'>
                    <div className='tablet_footer_wrapper-element'>
                        <Link
                            to='#'
                            onClick={(e) => {
                                window.location.href = 'mailto:mail@i-o.design';
                                e.preventDefault();
                            }}
                        >
                            mail@i-o.design
                        </Link>
                        <div className='tablet_footer-element-share'
                            onMouseEnter={() => setShowShareModal(true)}
                            onMouseLeave={() => setShowShareModal(false)}
                        >
                            share
                            <ShareIcon className='footer-share-icon' />

                            <div className='share_modal_tablet_footer' style={{ display: showShareModal ? 'flex' : 'none' }}>
                                <ShareModal type='tablet-footer' />
                            </div>

                        </div>
                    </div>
                    <div className='tablet_footer_wrapper-element '>
                        <p>Instagram</p>
                        <p>LinkedIn</p>
                    </div>
                    <div className='tablet_footer_wrapper-element'>
                        <p onClick={() => {
                            if (!email) {
                                openModal()
                            }
                        }}> {email ? `${email}` : 'sign up'}</p>
                        <Link to={'/'} onClick={() => setSectionId('features')}>coming Features</Link>

                    </div>
                    <div className='tablet_footer_wrapper-element'>
                        <Link to={'/'} onClick={() => setSectionId('vision')}>vision</Link>
                        <Link to={'/'} onClick={() => setSectionId('about')}>About</Link>
                    </div>
                    <div className='tablet_footer_wrapper-element'>
                        <Link to={'/terms'} onClick={scrollToTop}>terms of use</Link>
                        <Link to={'/privacy'} onClick={scrollToTop}>Privacy policy</Link>
                    </div>

                </div>
            </div>

            <Copyright />

            {/* <div className='tablet_footer_item'>
                <Logo />
                <p>mail@i-o.design</p>
            </div>
            <div className='tablet_footer_item'>
                <div className='tablet_footer_cell' style={{ borderRight: '1px solid #aaa8a5' }}>
                    <p>LinkedIn</p>
                </div>
                <div className='tablet_footer_cell'>
                    <p>INSTAGRAM</p>
                </div>
            </div>
            <div className='tablet_footer_item'>
                <Link to={'/terms'} onClick={scrollToTop} className='tablet_footer_cell' style={{ borderRight: '1px solid #aaa8a5' }}>
                    <p>terms & conditions</p>
                </Link>
                <Link to={'/privacy'} onClick={scrollToTop} className='tablet_footer_cell'>
                    <p>Privacy policy</p>
                </Link>

            </div>
            <div className='tablet_footer_item' id='copyright'>
                <Copyright />
            </div> */}

        </footer>

    )
}

export default TabletFooter;