import React, { FC, Fragment } from 'react';
import Media from 'react-media';

interface IMedia {
    mobile?: React.ReactNode;
    tablet?: React.ReactNode;
    desktop?: React.ReactNode;
}
const MyMedia: FC<IMedia> = ({ mobile, tablet, desktop }) => {
    return (
        <Media queries={{
            mobile: "(max-width: 650px)",
            medium: "(min-width: 650px) and (max-width: 850px)",
            large: "(min-width: 850px)"
        }}>
            {matches => {
                switch (true) {
                    case matches.mobile:
                        return <>{mobile}</>;
                    case matches.medium:
                        return <>{tablet}</>;
                    case matches.large:
                        return <>{desktop}</>;
                    default:
                        return null;
                }
            }}
            {/* {matches => {
                if (matches.mobile) {
                    return <>{mobile}</>;
                } else if (matches.medium) {
                    return <>{tablet}</>;
                } else if (matches.large) {
                    return <>{desktop}</>;
                }
            }} */}
        </Media>
    );
}

export default MyMedia;