import React from "react";
import './ButtonElement.scss'
import { goToService } from "services";

function ButtonElement() {
    return (
        <section id="about">
            <div className="button_element-wrapper">
                <h1>Help us improve using our demo</h1>
                <div className="button_element-about">
                    <p>
                    We are customizing emerging technologies to timeless creative workflows.
                    </p>
                    <p>
                    Demo allows realistic images generation combining concept sketches with design research findings. Assign products and materials to a sketch like in a coloring book. As straightforward as when you explain ideas to clients or colleagues through files or text messages. 
                    </p>
                    <p>
                    Interacting with <span className="underline">demo</span> you submit us a valuable use case.
                    </p>
                    <p>
                    Research demo participants will be granted a free access to tech demo coming in near future.
                    </p>
                    <p>
                    During the research demo, we are optimizing our technology to convert your submittals into images semi-manually. As a result, image production may take a couple of days.
                    </p>
                    <p>
                    However, with the tech demo, we already have plans to deliver technology that can render images instantly. Don't miss your chance, as our budget limits the access to only 1000 demo users.
                    </p>

                </div>
                <button onClick={()=>goToService()}>
                    try desktop demo
                </button>
            </div>

        </section>
    )
}

export default ButtonElement