import React, { useState } from "react";
import './CookieNotification.scss'

function CookieNotification() {
    const [showNotification, setShowNotification] = useState<boolean>(true)
    return (
        <>{
            showNotification ? (
                <div className="cookie_notification">
                    <div className="cookie_notification-info">
                        <p>This website uses cookies</p>
                        <p className="show_details">Show details</p>
                    </div>
                    <div className="cookie_notification-buttons">
                        <button onClick={() => setShowNotification(false)}>
                            reject
                        </button>
                        <button className="accept-button" onClick={() => setShowNotification(false)}>
                            accept
                        </button>

                    </div>

                </div>
            ) : (null)
        }

        </>
        // <div className="cookie_notification">
        //     <div className="cookie_notification-info">
        //         <p>This website uses cookies</p>
        //         <p className="show_details">Show details</p>
        //     </div>
        //     <div className="cookie_notification-buttons">
        //         <button>
        //             reject
        //         </button>
        //         <button className="accept-button">
        //             accept
        //         </button>

        //     </div>

        // </div>

    )
}

export default CookieNotification