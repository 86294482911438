import React, { useEffect, useState } from "react";
import './Header.scss'
import { ReactComponent as Logo } from '../../../icons/logo.svg'
import { ReactComponent as Share } from '../../../icons/Share.svg'
import { Link } from "react-router-dom";
import ShareModal from "../shareModal/ShareModal";
import { setSectionId } from "entities/sectionId";
import { openModal } from "entities/modal";
import { useStore } from "effector-react";
import { $email } from "entities/user";

function Header() {


    const email = useStore($email)
    const [showShareModal, setShowShareModal] = useState<boolean>(false)

    const scrollToTop = () => {
        setSectionId(null)
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const handleNavClick = (sectionId: string) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ block: 'start', behavior: 'smooth' });

        }
    };

    useEffect(() => {
        console.log(email)
    }, [email])


    return (
        <div className="header">
            <div className="header-right_parth">
                <Link to={'/'} onClick={scrollToTop} className="logo-item">
                    <Logo />
                </Link>
                <div className="header-item" id="register" style={{ textTransform: email ? 'none' : 'uppercase' }}
                    onClick={() => {
                        if (!email) {
                            openModal()
                        }

                    }}>
                    {email ? `${email}` : 'sign up'}
                </div>

            </div>
            <div className="header-left_parth">
                <nav className="header-item" onClick={() => handleNavClick('features')}>
                    coming Features
                </nav>
                <nav className="header-item" onClick={() => handleNavClick('vision')}>
                    Vision
                </nav>
                <nav className="header-item" onClick={() => handleNavClick('about')}>
                    About
                </nav>
                <nav className="header-item"
                    id="share"
                    onMouseEnter={() => setShowShareModal(true)}
                    onMouseLeave={() => setShowShareModal(false)}>
                    Share
                    <Share className="header-item-icon" />
                    <div className="share_modal_header" style={{ display: showShareModal ? 'flex' : 'none' }}>
                        <ShareModal />
                    </div>
                </nav>

            </div>
        </div>
    )
}

export default Header;
