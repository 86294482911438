import React, { useState } from "react";
import './MobileHeader.scss'
import { ReactComponent as Logo } from '../../../../icons/logo.svg'
import { ReactComponent as Menu } from '../../../../icons/BurgerMenu.svg'
import { ReactComponent as Share } from '../../../../icons/Share.svg'
import { Link } from "react-router-dom";
import { setSectionId } from "entities/sectionId";
import { useStore } from "effector-react";
import { openModal } from "entities/modal";
import { $email } from "entities/user";


function MobileHeader() {

    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
    const email = useStore($email)
    
    const handleShare = async () => {

        try {
            await navigator.share({
                // title: 'Заголовок вашей страницы',
                text: 'Launch on Desktop',
                url: window.location.href,
            });
        } catch (error) {
            console.log(error);
        }

    };

    const scrollToTop = () => {
        setSectionId(null)
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const hadleMenuItemClick = (type: string) => {
        setIsMenuOpen(false)
        setSectionId(type)

    }

    return (
        <div className="mobile_header">
            <Link to={'/'} onClick={scrollToTop}>
                <Logo height={20} />
            </Link>

            <div onClick={() => setIsMenuOpen((prev) => !prev)}>
                {isMenuOpen ? <p>Close</p> : <Menu />}

            </div>
            <div className={`menu ${isMenuOpen ? 'menu_open' : ''}`}>
                <div className="menu_wrapper">
                    <div className="menu_options">
                    <p onClick={() => {
                        if (!email) {
                            openModal()
                        }
                    }}> {email ? `${email}` : 'sign up'}</p>

                        <Link to={'/'} onClick={() => hadleMenuItemClick('features')}>Coming features</Link>
                        <Link to={'/'} onClick={() => hadleMenuItemClick('vision')}>Vision</Link>
                        <Link to={'/'} onClick={() => hadleMenuItemClick('about')}>About</Link>
                    </div>
                    <div className="menu_lins">
                        <p>mail@i-o.design</p>
                        <p>LinkedIn</p>
                        <p>Instagram</p>
                    </div>

                    <button className="share_button" onClick={handleShare}>
                        try demo on desktop
                        <Share className="share_button-icon" />
                    </button>
                </div>


            </div>


        </div>
    )
}

export default MobileHeader;
