import React, { useState } from "react";
import './Auth.scss'
import Email from "./components/email/Email";
// import Verification from "./components/verification/Verification";
import Survey from "./components/survey/Survey";
// import Gratitude from "./components/gratitude/Gratitude";


enum Step {
    SURVEY,
    EMAIL,
    VERIFICATION,
    GRATITUDE,
}

function Auth() {
    const [step, setStep] = useState<Step>(Step.EMAIL);
    // const [tempUserEmail, setTempUserEmail] = useState<string | undefined>(undefined)

    return (
        <div className="auth__container">
            {step === Step.SURVEY && <Survey onButton={() => setStep(prev => prev + 1)} />}
            {step === Step.EMAIL && <Email />}
            {/* {step === Step.EMAIL && <Email onNextStep={() => setStep(prev => prev + 1)} setTempUserEmail={setTempUserEmail}/>}
            {step === Step.VERIFICATION && <Verification onNextStep={() => setStep(prev => prev + 1)} userEmail={tempUserEmail} />}
            {step === Step.SURVEY && <Survey onNextStep={() => setStep(prev => prev + 1)} />}
            {step === Step.GRATITUDE && <Gratitude/>} */}

        </div>
    )
}

export default Auth;