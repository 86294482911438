export interface IFeature {
  type: string;
  promo: string;
  descr: string;
  featureList: string[];
}

export interface WordI {
  word: string;
  position: number;
}

export interface TextI {
  line:WordI[];
  scrollposition: number
}

export const featuresItems: IFeature[] = [
  {
    type: "input",
    promo: "Search less — research more",
    descr:
      "collect files easily and meaningfully in a custom digital asset management service",
    featureList: [
      "enjoy AI tagging tailored to architecture and design industry",
      "navigate product libraries by topics, similar to a webstore",
    ],
  },
  {
    type: "dashboard",
    promo: "Single source of truth",
    descr:
      "organize project files and ideas in a requirements management dashboard",
    featureList: [
      "Keep project requirements, brief, client needs, and agenda aligned",
      "Create interactive specifications and gain AI insights",
    ],
  },
  {
    type: "output",
    promo: "Search less — research more",
    descr:
      "collect files easily and meaningfully in a custom digital asset management service",
    featureList: [
      "enjoy AI tagging tailored to architecture and design industry",
      "navigate product libraries by topics, similar to a webstore",
    ],
  },
];

export let textArr: TextI[] = [
  {
    line: [
      {
        word: "Immerse",
        position: 0.01,
      },
      {
        word: "yourself",
        position: 0.06,
      },
      {
        word: "in",
        position: 0.12,
      },
      {
        word: "the",
        position: 0.18,
      },
      {
        word: "real",
        position: 0.24,
      },
    ],
    scrollposition: 0.20,
  },
  {
    line: [
      {
        word: "world,",
        position: 0.01,
      },
      {
        word: "sketching",
        position: 0.06,
      },
      {
        word: "designs,",
        position: 0.12,
      },
    ],
    scrollposition: 0.5,
  },
  {
    line: [
      {
        word: "exploring",
        position: 0.01,
      },
      {
        word: "literature",
        position: 0.06,
      },
      {
        word: "and",
        position: 0.12,
      },
      {
        word: "trade",
        position: 0.18,
      },
    ],
    scrollposition: 0.7,
  },
  {
    line: [
      {
        word: "fairs",
        position: 0.01,
      },
      {
        word: "collaborating",
        position: 0.06,
      },
      {
        word: "with",
        position: 0.12,
      },
      {
        word: "teams",
        position: 0.18,
      },
    ],
    scrollposition: 0.9
  },
  {
    line: [
      {
        word: "and",
        position: 0.06,
      },
      {
        word: "clients,",
        position: 0.12,
      },
      {
        word: "discovering",
        position: 0.18,
      },
    ],
    scrollposition: 1.1
  },
  {
    line: [
      {
        word: "inspiration, ",
        position: 0.12,
      },
      {
        word: "and",
        position: 0.18,
      },
      {
        word: "conducting",
        position: 0.24,
      },
    ],
    scrollposition: 1.2
  },
  {
    line: [
      {
        word: "valuable",
        position: 0.24,
      },
      {
        word: "research.",
        position: 0.28,
      },

    ],
    scrollposition: 1.3
  },
];



export let tabletTextArr: TextI[] = [
  {
    line: [
      {
        word: "Immerse",
        position: 0.01,
      },
      {
        word: "yourself",
        position: 0.06,
      },
      {
        word: "in",
        position: 0.12,
      },
      {
        word: "the",
        position: 0.18,
      },
      {
        word: "real",
        position: 0.24,
      },
    ],
    scrollposition: 0.40,
  },
  {
    line: [
      {
        word: "world,",
        position: 0.01,
      },
      {
        word: "sketching",
        position: 0.06,
      },
      {
        word: "designs,",
        position: 0.12,
      },
    ],
    scrollposition: 0.7,
  },
  {
    line: [
      {
        word: "exploring",
        position: 0.01,
      },
      {
        word: "literature",
        position: 0.06,
      },
      {
        word: "and",
        position: 0.12,
      },
      {
        word: "trade",
        position: 0.18,
      },
    ],
    scrollposition: 0.9,
  },
  {
    line: [
      {
        word: "fairs",
        position: 0.01,
      },
      {
        word: "collaborating",
        position: 0.06,
      },
      {
        word: "with",
        position: 0.12,
      },
      {
        word: "teams",
        position: 0.18,
      },
    ],
    scrollposition: 1.1
  },
  {
    line: [
      {
        word: "and",
        position: 0.01,
      },
      {
        word: "clients,",
        position: 0.06,
      },
      {
        word: "discovering",
        position: 0.12,
      },
    ],
    scrollposition: 1.3
  },
  {
    line: [
      {
        word: "inspiration, ",
        position: 0.01,
      },
      {
        word: "and",
        position: 0.06,
      },
     
    ],
    scrollposition: 1.5
  },
  {
    line: [
      {
        word: "conducting",
        position: 0.01,
      },
      {
        word: "valuable",
        position: 0.06,
      },
      {
        word: "research.",
        position: 0.12,
      },

    ],
    scrollposition: 1.7
  },
];
