import { ReactNode } from 'react';
import './Modal.scss'



type ModalProps = {
    children: ReactNode;
    onClose: () => void;
    textButton: string;
    isButtonDisabled?: boolean;
    onButton: () => void;

};

export default function Modal({ children, onClose, textButton, isButtonDisabled, onButton }: ModalProps) {
    return (
        <div className='auth-modal'>
            <div className="auth-modal__close-button" onClick={onClose} />
            {children}
            <button className='auth-modal__button' onClick={onButton} disabled={isButtonDisabled}>{textButton}</button>
        </div>
    );
}
