import { useState } from 'react';

import { closeModal } from 'entities/modal';
import './Survey.scss';
import Modal from '../modal/Modal';
import Radio from './radio/Radio';
import Select from './select/Select';


type SurveyProps = {
    onButton: () => void;
}

export default function Survey({ onButton }: SurveyProps) {
    const [number, setNumber] = useState<string | null>(null);
    const [profession, setProfession] = useState<string | null>(null);

    return (
        <Modal onClose={closeModal} textButton="CONTINUE" onButton={onButton}>
            <div className="survey__title">Fill in survey</div>
            <div className="survey__select-label">PROFESSION</div>
            <Select className='survey__select' items={['p1', 'p2', 'other']} value={profession} setValue={setProfession}/>
            <div className="survey__radio-label">NUMBER OF CREATIVE PROFESSIONALS IN YOUR COMPANY</div>
            <Radio className="survey__radio" collections={['1-10', '11-50', '51+']} value={number} setValue={setNumber} />
        </Modal>
    );
}